const addlogo = () => {
  const logo = document.getElementById('logo-printable')
  logo.classList.remove('d-none')
}

const removelogo = () => {
  const logo = document.getElementById('logo-printable')
  logo.classList.add('d-none')
}
export { addlogo, removelogo }
