import { animationScroll } from '../../utilities/animationScroll.js'

const addAnchors = () => {
  const element = document.getElementById('sheet-portrait-show')
  if (element) {
    let h1s = document.querySelectorAll('h1, h2')
    let new_h4s = []
    // h1s.forEach((h1) => {
    //   var dummy = document.createElement('h4');
    //   dummy.innerHTML =h1.innerHTML;
    //   // h1.parentNode.replaceChild(dummy, h1);
    //   console.log(h1.replaceWith(dummy))
    //   new_h4s.push(dummy)
    // })

    h1s.forEach((h1) => {
      const h1Formater = h1.textContent.toLowerCase().split(' ').join('-').replace(/'/g,'-');
      let anchor = h1Formater.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      anchor = anchor.replace(/’/g,'-')
      anchor = anchor.trim()


      h1.insertAdjacentHTML('beforebegin', `<div id='#${anchor}'></div>`)
      const catchAnchor = document.getElementById(`${anchor}-anchor`)
      animationScroll(catchAnchor)
    })
  }
}

export { addAnchors }
