// import { buildMarkerLegend } from './marker.js'
import { buildCanvas } from '../canvas.js'
const buildLegendsCanvas = () => {
  const legends = document.querySelectorAll('.legend-box:not(.d-none)');

  legends.forEach((legend) => {
    legend.querySelector('.box-options-legend').classList.toggle('d-none');
    // const isMarker = legend.classList.contains('marker-printable-legend-box')
    // if (isMarker) {
    //   buildMarkerLegend(legend);

    // } else {
    //   buildCanvas({
    //     'element': legend,
    //     'urlCanvas': false
    //   });
    // }
    buildCanvas({
        'element': legend,
        'urlCanvas': false
      });
    legend.querySelector('.box-options-legend').classList.toggle('d-none');

  })
  // const boxLegend = document.getElementById('legend-canvas-box-build');
  // boxLegend.insertAdjacentHTML('beforebegin', "<h3 class='text-center'>Légende(s)</h3>");
}

export { buildLegendsCanvas }
