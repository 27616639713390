const legend = (styleParams) => {
  const canvasBoxBuild = document.getElementById("canvas-box-build");
  const boxLegend = document.getElementById('legend-canvas-box-build');
  legendBox(styleParams, boxLegend);
  // legendMarker(styleParams,canvasBoxBuild);
  legendAplatCircle(styleParams,boxLegend);
}
const legendBox = (styleParams,boxLegend) => {
  boxLegend.insertAdjacentHTML('beforebegin', `
    <h3 style="text-align:left;
    margin-top:32px;
    text-decoration:underline;
    width:${styleParams.mapWidth};
    margin-left:${styleParams.marginCenter};">
    Légende(s)</h3>`);

  boxLegend.style.borderLeft = '1px';
  boxLegend.style.borderRight = '0px';

  boxLegend.style.borderLeft = 'solid';
  boxLegend.style.marginLeft = styleParams.marginCenter;
  boxLegend.style.marginRight = styleParams.marginCenter;
  boxLegend.style.width = styleParams.legendBoxWidth;
  boxLegend.style.borderColor = 'rgba(0,0,0,0.2)';
  boxLegend.style.padding = '8px';
}
const legendAplatCircle = (styleParams,boxLegend) => {

  // const legendImgs = boxLegend.querySelectorAll('.legend-canvas')
  const legendImgs = document.querySelectorAll('.legend-canvas');
  if (legendImgs) {
    legendImgs.forEach((img) => {

      img.style.width = styleParams.legendWidth;
      img.style.marginTop = '16px';
      img.style.marginBottom = '16px';
    });

  }
}

// const legendMarker = (styleParams,canvasBoxBuild) => {
//   const legendMarker = canvasBoxBuild.querySelector('.canvas-element-marker-box')
//   if (legendMarker) {
//     legendMarker.style.maxWidth = '900px';
//     legendMarker.style.display = 'flex';
//     legendMarker.style.marginLeft = '8px';
//     legendMarker.style.marginTop = '16px';
//     legendMarker.style.marginBottom = '16px';
//     legendMarker.querySelector('img').style.margin = '0 18px 0 0';

//   }
// }

// export { legend, legendMarker }
export { legend }
