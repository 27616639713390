
const animationScroll = (anchor) => {
  // if (document.querySelector(anchor)) {
    if (anchor) {

      if (window.innerWidth > 990) {
        anchor.addEventListener('click', function (e) {
              e.preventDefault();
              document.getElementById(this.getAttribute('href')).scrollIntoView({
                  behavior: 'smooth'
              });
          });
      } else {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            document.getElementById(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
      }
    }
    // }
}

export { animationScroll }
