import { setAplatStep } from './step.js'
const setAplatColor = () => {
  const inputColor = document.querySelector('.aplat-color-catch')

  if (inputColor) {
    paletteColorSelected()
    inputColor.addEventListener('blur', (event) => {
      const colors = event.currentTarget.value.split('||')
      start(colors,inputColor)
    })

    inputColor.addEventListener('focus', (event) => {
      const colors = event.currentTarget.value.split('||')
      start(colors,inputColor)
    })

  }

}

const start = (colors,inputColor) => {

  // const c = document.getElementById('tilset_mapboxe_aplat_colors')
  inputColor.value = inputColor.value.replace(/[' ']/g, '||')

  const demoColorBox = document.querySelector('.demo-color-boxs')
  const demoCadresColor = demoColorBox.querySelectorAll('.demo-cadre-color')

  paint_color(colors,demoCadresColor)

}

const paint_color = (colors,demoCadresColor) => {
  let i = 0
  demoCadresColor.forEach((cadre) => {
    cadre.style.backgroundColor = 'white'
  })

  colors.forEach((color) => {
    if (demoCadresColor[i]) {
      demoCadresColor[i].style.backgroundColor = color

      i = i + 1
    }
  })

}


const paletteColorSelected = () => {
  const inputJenks = document.getElementById('nb_class_discretization')
  const linkPalette = document.getElementById('palette-color-example')
  const formLegend = document.getElementById('legend-form')
  if (formLegend) {

  } else {
    if (inputJenks) {
      const nbClass = parseInt(inputJenks.value) + 1
      linkPalette.href = `https://colorbrewer2.org/#type=sequential&scheme=BuGn&n=${nbClass}`

    }
    if (inputJenks) {
      inputJenks.addEventListener('change', (event) => {
        const nbClass = parseInt(inputJenks.value) + 1
        linkPalette.href = `https://colorbrewer2.org/#type=sequential&scheme=BuGn&n=${nbClass}`
      })
    }
  }
}
export { setAplatColor, paint_color }
