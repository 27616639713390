import { container } from './container.js';
import { mapStyle } from './map.js';
import { legend, legendMarker } from './legend.js';
import { logo } from './logo.js';

const indexStyle = (styleParams,canvasBoxBuild) => {
  container(styleParams);
  logo(styleParams);
  mapStyle(styleParams)
  legend(styleParams);
}

export { indexStyle }
