const container = (styleParams) => {
  const canvasBoxBuild = document.getElementById("canvas-box-build");
  canvasBoxBuild.style.width = styleParams.containerWidth
  // canvasBoxBuild.style.textAlign = 'center';
  let hrs = canvasBoxBuild.querySelectorAll('hr');
  if (hrs[0]) {
    hrs[0].remove();
  }
  hrs.forEach((hr) => {
    hr.style.border = '1px solid';
    hr.style.borderColor = 'rgba(0,0,0,0.1)';
  })
}


export { container }
