import { stylePrintable } from './settings.js';
import { indexStyle } from './components/index.js';
// import { container } from './components/container.js';
// import { mapStyle } from './components/map.js';
// import { legend, legendMarker } from './components/legend.js';
// import { logo } from './components/logo.js';

const buildStyle = () => {
  const styleParams = stylePrintable();

  indexStyle(styleParams)


}

export { buildStyle }
