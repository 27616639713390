const addLoading = (btn) => {
  btn.querySelector('.ri-printer-line').classList.toggle('d-none');
  btn.insertAdjacentHTML('afterbegin', `<div class="spinner-border text-dark" role="status" style='font-size:8px;
    width:16px;height:16px;'>
    <span class="visually-hidden"></span>
  </div>`);
  btn.disabled = true;
}

const removeLoading = (btn) => {
  btn.querySelector('.ri-printer-line').classList.toggle('d-none');
  btn.querySelector('.spinner-border').remove();
  btn.disabled = false;
}

export { addLoading,removeLoading }
