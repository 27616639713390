import { cleanMapboxBrand } from '../utilities/cleanMapboxBrand.js';
import { autocompleteForm,removeInput } from 'components/autocomplete/datasetCulture.js';
import { addBtn, deleteBtn } from 'components/autocomplete/addDeleteBtn.js';
import { jenks } from 'components/form/discretization/jenks.js'
import { setAplatColor } from 'components/form/discretization/aplat/color.js'
import { setAplatStep } from 'components/form/discretization/aplat/step.js'
import { addAnchors } from 'components/sheetPortrait/anchors.js'
import { bannerCollapse } from 'components/map/domTom/banner.js'
import { goToRegion } from 'components/map/goToRegion.js';
// import { copy } from '../utilities/copy.js'
import { startPrintable } from '../utilities/printable/start.js'
import { notePage } from '../components/sheetPortrait/notePage.js'

document.addEventListener('turbolinks:load', () => {

  cleanMapboxBrand();
  startPrintable();
  // copy();
  // const inputElement = document.getElementById('tilset_mapboxe_dataset_id')
  const inputElements = document.querySelectorAll('.autocomplete-form-box-input > input')
  addBtn();
  deleteBtn();
  inputElements.forEach((inputElement) => {

    if (inputElement) {

      autocompleteForm(inputElement);
      removeInput(
        inputElement,
        inputElement.nextElementSibling.id);
    }

  })
  // const inputElement2 = document.getElementById('tilset_mapboxe_dataset_geo_id')

  // if (inputElement2) {
  //   autocompleteForm(inputElement2,'autocomplete-2');
  //   removeInput(inputElement2,'remove-input-2');

  // }

  jenks();
  setAplatColor();
  setAplatStep()

  addAnchors()
  bannerCollapse();
  goToRegion();
  notePage();

  const frLogos = document.querySelectorAll(".fr-logo");
  frLogos.forEach((frLogo) => {
    frLogo.ariaLabel = `Logo : ${frLogo.innerText}. Liberté. Égalité. Fraternité`

  })
})
