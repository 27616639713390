import { paint_color } from './color.js'
const setAplatStep = () => {

  let inputStep = document.querySelector('.aplat-step-catch')

  if (inputStep) {

    const steps = inputStep.value.split('||')
    start(steps)

    inputStep.addEventListener('change', (event) => {
      const steps = event.currentTarget.value.split('||')
      start(steps)
    });
    inputStep.addEventListener('focus', (event) => {
      const steps = event.currentTarget.value.split('||')
      start(steps)
    });



  }
}

const start = (steps) => {

  const demoStepBox = document.querySelector('.demo-step-boxs')
  const demoColorBox = document.querySelector('.demo-color-boxs')

  const catchColor =  document.querySelector('.aplat-color-catch')
  if (steps[0] === '') {
    if (catchColor) {
      catchColor.disabled = true
    }

  } else {
    if (catchColor) {
      catchColor.disabled = false
    }

  }
  const demoCadresStep = document.querySelectorAll('.demo-cadre-step')

  cleanDemoBox(demoStepBox)
  cleanDemoBox(demoColorBox)

  steps.forEach((step) => {
    addInfoStepBox(step,demoStepBox)
    addInfoStepBox(step,demoColorBox)

  })

  // const colors = document.getElementById('tilset_mapboxe_aplat_colors').value.split('||')
  const colors = document.querySelector('.aplat-color-catch').value.split('||')
  const demoCadresColor = demoColorBox.querySelectorAll('.demo-cadre-color')

  paint_color(colors,demoCadresColor)

}
const cleanDemoBox = (demoBox) => {

  demoBox.innerText = ''
}
const addInfoStepBox = (step,demoBox) => {
    let stepForma = 0
    if (isNaN(step)) {
      stepForma = step
    } else {
      stepForma = Math.round(step)
    }
    const html = `<p class='demo-cadre demo-cadre-step demo-cadre-color'>
    ${stepForma}
    </p>`
    demoBox.insertAdjacentHTML('beforeend',html)

}
const removeStepDemo = () => {
  const stepsIndicators = document.querySelectorAll('.aplat-step-indicator')
  stepsIndicators.forEach((stepsIndicator) => {
    stepsIndicator.innerText = ''
  })
}
export { setAplatStep }
