import { buildMapCanvas } from './map/canvas.js';
import { buildLegendsCanvas } from './legends/canvas.js';
import { addLoading, removeLoading } from './loading.js'
import { printable } from './print.js';
import { addlogo, removelogo } from './brand/logo.js'
import { buildStyle } from './style/buildStyle.js';
const startPrintable = async (displayMediaOptions) => {
  if (document.getElementById('map')) {
    const btn = document.getElementById("btn-screen-shot")
    if (btn) {
      btn.addEventListener('click', (event) => {
        // --------------
          event.preventDefault();

          // map.flyTo({
          //     zoom: 5
          // });
          addLoading(btn);
          buildMapCanvas();
          buildLegendsCanvas();
          setTimeout(addlogo, 1000);
          setTimeout(function() {
              addlogo();
              buildStyle();
          }, 1100)

          setTimeout(printable, 1200);
          setTimeout(function() {
              removeLoading(btn);
              removelogo();
              removeScreenShot();
              // map.flyTo({
              //   zoom: 4
              // });
          }, 1200)
      });
    }
  }

};

const removeScreenShot = () => {
  document.getElementById('map-canvas-box-build').innerHTML = '';
  document.getElementById('legend-canvas-box-build').innerHTML = '';
  document.getElementById('canvas-box-build').querySelector('h3').remove();
};

export { startPrintable }



// const targetElements = () => {
//   const menu = document.querySelector('.part-menu');
//   const regionDomTom = document.querySelector('.region-dom-tom-wrapper');
//   const btnScreenShot = document.querySelector('#btn-screen-shot')
//   const params = {
//     'menu': menu,
//     'regionDomTom': regionDomTom,
//     'btnScreenShot': btnScreenShot,
//     'navbarDesktop': document.querySelector('.navbar-desktop'),
//     'btn-reodoring': document.getElementById('reodering-layer'),
//     'btn-download': document.getElementById('btn-download-data')

//   }
//   return params
// }
// const dNoneElements = () => {
//   const params = targetElements()
//   for (const [key, value] of Object.entries(params)) {
//     displayElement({
//     'element' : value,
//     'typeDisplay' : 'none'})
//   }
//   document.getElementById('map').height = '100vh';
// }
// const dElements = () => {
//   const params = targetElements()
//   for (const [key, value] of Object.entries(params)) {

//     displayElement({
//     'element' : value,
//     'typeDisplay' : 'true'})
//   }
//   // displayElement({
//   //   'element' :params.menu,
//   //   'typeDisplay': 'true'})
// }
// const displayElement = (params) => {

//   if (params.typeDisplay === 'none') {
//     params.element.classList.add('d-none');
//   } else {
//     params.element.classList.remove('d-none');

//   }
// }
          // setTimeout(function() {
          //     removeScreenShot();
          // }, 1100)



        // --------------
        // --------------
          // console.log(map.getCanvas().toDataURL())
          // const img = map.getCanvas().toDataURL('image/png')
          // event.currentTarget.href = img
        // --------------

        // --------------
          // event.preventDefault();
          // window.addEventListener("beforeprint", (event) => {
          //   dNoneElements();
          // });
          // window.onafterprint = (event) => {
          //   dElements();
          // };
          // window.print();
        // --------------
