import html2canvas from 'html2canvas';

const buildCanvas = (params) => {
  let urlCanvas = '';
  const canvasBox = document.getElementById('canvas-box-build')

  if (params.urlCanvas) {
    urlCanvas = params.urlCanvas
    // width=${params.width}


    canvasBox.querySelector('#map-canvas-box-build').insertAdjacentHTML('afterbegin',
        `<img src="${urlCanvas}" alt="image printable">`)
  } else {
    html2canvas(params.element,
      {
        imageTimeout: 0,
        removeContainer: false
      })
      .then(function(canvas) {
        urlCanvas = canvas.toDataURL('image/png');
        const html = buildHtml(params,urlCanvas)
        canvasBox.querySelector('#legend-canvas-box-build').insertAdjacentHTML('afterbegin',html)
      });
  }
}


const buildHtml = (params,urlCanvas) => {
  let imgElement = ''
  let html = ''
  if (params.element.querySelector('img') && !params.element.querySelector('img').src.includes('assets/icones')) {

    const imgSrc = params.element.querySelector('img').src;
    imgElement = `<img src="${imgSrc}" alt="image icone" width="16px">`
    html = `<hr>
      <div style="display:flex;
      justify-content: start;
      align-items:center;
      padding:12px;">
        ${imgElement}
        <img src="${urlCanvas}" alt="image printable"
        class='legend-canvas'>
      </div>`
  } else {
    html = `<hr>
        ${imgElement}
        <img src="${urlCanvas}" alt="image printable"
        class='legend-canvas'>`
  }
  return html
}
export { buildCanvas }

  // html2canvas(element, {
  //   onrendered: function(canvas) {
  //     const urlCanvas = canvas.toDataURL('image/png')
  //     document.body.insertAdjacentHTML('beforeend', `<img src="${urlCanvas}" alt="" id="canvas-${id}">`)
  //   }
  // });
