import { animationScroll } from '../../utilities/animationScroll.js'

const notePage = () => {
    if (document.getElementById('sheet-portrait-show')) {
    const links = document.querySelectorAll('a')
    links.forEach((link) => {
      if (link.href.includes("ref-")) {
        const linkTab = link.href.split("#")
        const linkPart = linkTab[linkTab.length - 1]
        if (link.href.includes("#ancre-ref-")) {
          let number = linkPart.split('ancre-ref-')
          number = number[number.length - 1]
          link.id = `#${linkPart}`
          link.title = `note numero : ${number}`
          link.classList.add("survol-marker");
          link.href = `#${linkPart.split("ancre-").pop()}`
// Revert
          link.insertAdjacentHTML('beforebegin', `<span id=#revert-${linkPart}></span>`);
// Revert#revert-ancre-ref-1

          animationScroll(link)

        } else if (link.href.includes("#ref-")){
          let number = linkPart.split('ref-')
          number = number[number.length - 1]
          link.insertAdjacentHTML('beforebegin', `<span id=#${linkPart} style="color:blue;">[${number}]</span>`);
          // Revert

          link.insertAdjacentHTML('beforebegin', ` <a id=#revert-id-${linkPart} title='note numero : ${linkPart}' href=#revert-ancre-${linkPart} style="color:blue;"><i class="ri-sort-asc"></i></a>`);
          const linkRevert = document.getElementById(`#revert-id-${linkPart}`)
          animationScroll(linkRevert)
// Revert
          link.remove()
        }
      }
    })
  }
}

export { notePage }
