import { autocompleteForm,removeInput } from 'components/autocomplete/datasetCulture.js';

const addBtn = () => {
  const btn = document.getElementById('btn-add-input')
  let iterator = document.querySelectorAll('#search-input-add > div').length
  if (btn) {

    btn.addEventListener('click',(event) => {
      event.preventDefault();

      iterator = iterator + 1
      const box = document.getElementById('search-input-add')
      const html =
      `
      <div class="pb-2 pl-2 pr-2 pt-0 mb-2 add-btn-lib-geo">
        <div class='d-flex align-items-center'>
          <div class="autocomplete-form-box flex-grow-1">
            <div class="autocomplete-form-box-input d-flex justify-content align-items-center">
              <input type="text" name="search-lib-geo[params-${iterator}]" id="autocomplete-form-input-${iterator}" class="autocomplete-form-box-input" autocomplete="off" placeholder="Rechercher par nom ">
              <span id="remove-input-${iterator} %>" class="remove-input-style">X</span>
            </div>
          </div>
        </div>
        <ul id="autocomplete-${iterator}" class="autocomplete-style"></ul>
      </div>`
          // <button class="text-ac delete-btn" style="box-shadow: none;">
          //  <i class="ml-3 ri-delete-bin-6-line"></i>
          // </button>

      box.insertAdjacentHTML('beforeend',  html)
        const inputElement = document.getElementById(`autocomplete-form-input-${iterator}`)
        autocompleteForm(inputElement,`autocomplete-${iterator}`);
        removeInput(
          inputElement,
          inputElement.nextElementSibling.id
          );

    })
  }

}

const deleteBtn = () => {
  const box = document.getElementById('search-input-add');
  const btnDelete = document.querySelector('.delete-btn');
  if (btnDelete) {
    btnDelete.addEventListener('click',(event) => {
      event.preventDefault();
      if (box) {
        const btnAdd = box.querySelectorAll('.add-btn-lib-geo')
        if (btnAdd && btnAdd.length > 0) {
          btnAdd[btnAdd.length -1].remove()
        }
      }
    })
  }
  // const btns = document.querySelectorAll('.delete-btn')

  // btns.forEach((btn) => {
  //   btn.addEventListener('click', (event) => {
  //     event.preventDefault();
  //     // event.currentTarget.parentNode.parentNode.remove()
  //   })
  // })
}
export { addBtn,deleteBtn }
