const autocompleteForm = (inputElement) => {
  const autoComplete = inputElement.parentElement.parentElement.parentElement.nextElementSibling

  inputElement.addEventListener('keyup', (event) => {
    const letters = event.currentTarget.value;
    // let url = document.getElementById("dataset").selectedOptions[0].dataset.viewUrl
    let url = document.querySelectorAll('#radio-button-dataset input:checked')[0].dataset.viewUrl
    const libGeo = document.getElementById('infos-dataviz').dataset.libGeo
    if (url != '') {
      url = `${url}&q=${letters}`
    } else {
      url = `https://data.culture.gouv.fr/api/datasets/1.0/search/?disjunctive.theme=true&disjunctive.keyword=true&sort=modified&q=${letters}&rows=10&start=0&extrametas=true&interopmetas=true&timezone=Europe%2FBerlin&lang=fr`
    }

    if (!autoComplete.classList.contains("box-autocomplete-fix")) {
      autoComplete.classList.add('box-autocomplete-fix')
    }

    const myHeaders = new Headers();
    myHeaders.append('Authorization', 'Apikey 9ef9e5a20efb040d3ad4efd3124c7d18ffa656351910f51c5b768cf9');
    const myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'no-cache' };

    if (letters != '') {
      fetch(url,myInit)
        .then(response => response.json())
        .then((data) => {
          autoComplete.innerText = ""

          const records = data['records']
          if (records.length == 0) {

            autoComplete.insertAdjacentHTML('beforeend', `<li class="addresse-autocomplete d-flex align-items-center">
                  <i class="ri-map-pin-2-fill text-danger" data-dataset-id=""></i>
                  Pas de résultat <br>
                  </li>`);
              innerInput(autoComplete, inputElement);

          } else {
            let tabindexIterator = 0
            records.forEach((record) => {
              if (autoComplete.classList.contains("fix-height") == false) {
                autoComplete.classList.add('fix-height')

              }
              const valueAutoComplete = record['fields'][`${libGeo}`];

              autoComplete.insertAdjacentHTML('beforeend', `<li tabindex=${tabindexIterator} class="addresse-autocomplete d-flex align-items-center">
                  <button type="button" title=${valueAutoComplete}>
                    <i class="ri-map-pin-2-line text-success" data-dataset-id="${valueAutoComplete}" ></i>
                    ${valueAutoComplete}
                  </button>`);
              tabindexIterator += 1
              innerInput(autoComplete, inputElement);
            })
          }

        });
    }
  });
}

const innerInput = (autoComplete, inputElement) => {

  const autocompletionSuggestions = document.querySelectorAll('.addresse-autocomplete');
  autocompletionSuggestions.forEach((autocompletionSuggestion) => {

    autocompletionSuggestion.addEventListener('click', (event) => {
      const value_information = event.currentTarget.querySelector('i').dataset.datasetId.trim()

      inputElement.value = value_information.trim()

      manageBoxAutocomplement(autoComplete, inputElement)
    });
  });

};

const removeInput = (addressInput, removeId) => {
  const btnRemove = document.getElementById(removeId)
  if (btnRemove) {
    btnRemove.addEventListener('click', (event) => {
      addressInput.value = ''
      // const autoComplete = document.getElementById('autocomplete');
      const autoComplete = event.currentTarget.parentElement.parentElement.parentElement.parentElement.querySelector('ul')
      if (autoComplete.classList.contains("fix-height")) {
        autoComplete.classList.remove('fix-height')
      }
    })

  }
}


const manageBoxAutocomplement = (autoComplete, addressInput) => {

  if (autoComplete.classList.contains("box-autocomplete-fix")) {
    autoComplete.classList.remove('box-autocomplete-fix')
  }
  if (autoComplete.classList.contains("fix-height")) {
    autoComplete.classList.remove('fix-height')
  }
  autoComplete.innerText = ''

}

export { autocompleteForm, removeInput }





