const stylePrintable = () => {
  return {
    'containerWidth': '1000px',
    'marginCenterContainer': '45%',
    'mapWidth': '100%',
    'marginCenter': '0%',
    'legendBoxWidth': '50%',
    'legendWidth': '40%',
  }
}

export { stylePrintable }
