const jenks = () => {
  const btn = document.getElementById('discretization-jenk')
  if (btn) {
    btn.addEventListener('click', (event) => {
      const url = event.currentTarget.dataset.urlApiCulture
      const api_key_atlas = event.currentTarget.dataset.apiKeyAtlas
      const dataset_url = document.getElementById('tilset_mapboxe_url_data').value
      const datanameId = document.getElementById('tilset_mapboxe_url_data').dataset.datanameId
      let nb_class = document.getElementById('nb_class_discretization').value
      nb_class = parseInt(nb_class)
      const metrique = document.getElementById('tilset_mapboxe_metrique').value
      nb_class = nb_class - 1
      const url_dataset = `${url}?data_url_api=${dataset_url}&nb_class=${nb_class}&metrique=${metrique}&api_key=${api_key_atlas}`

      let urlPaletteExample = document.getElementById("palette-color-example")
      urlPaletteExample.href = `https://colorbrewer2.org/#type=sequential&scheme=BuGn&n=${nb_class + 1}`
      const loading = `
      <p class='m-3'>
        <div class="spinner-border text-primary" id='loading-discretization' role="status">
          <span class="visually-hidden"></span>
        </div>
      </p>`

      btn.insertAdjacentHTML('beforebegin',loading)

      fetch_url(url,dataset_url,nb_class,metrique,api_key_atlas,btn,datanameId);

    })
  }
}

const fetch_url = (url,dataset_url,nb_class,metrique,api_key,btn,datanameId) => {

  fetch(url, {
      method: 'POST',
      headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'mode': 'cors'
      },
      body: JSON.stringify(
        { "dataset_id": datanameId,
        "data_url_api": dataset_url,
        "nb_class": nb_class,
        "metrique": metrique,
        "api_key": api_key })
  })
  .then(response => response.json())
  .then((data) => {
    const response = data['response'].join('||')
    document.getElementById('loading-discretization').remove()
    insertResponse(response,btn)
  }).catch(function(error) {
    document.getElementById('loading-discretization').remove()
      btn.insertAdjacentHTML('beforebegin',`
      <p class='m-3' id="response-discretization-step">
      Erreur
      </p>
    `)
    // console.log('Request failed', error)
  })
}

const insertResponse = (response,btn) => {
  var event = new Event('change');
  const r = document.getElementById('response-discretization-step')
  if (r) {
    r.remove()
  }
  btn.insertAdjacentHTML('beforebegin',`
    <p class='m-3' id="response-discretization-step">
    ${response}
    </p>
    `)
  let inputStep = document.getElementById('tilset_mapboxe_aplat_steps')
  if (inputStep) {

  } else {
    inputStep = document.getElementById('legend_aplat_steps')
  }
  inputStep.value = response
  inputStep.dispatchEvent(event);
}

export { jenks }
