const printable = () => {
  const printContent = document.getElementById('canvas-box-build');
  const WinPrint = window.open('', 'Atlas Culture', 'width=900,height=650');
  WinPrint.document.write(printContent.innerHTML);
  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();
  WinPrint.close();
}
export { printable }
