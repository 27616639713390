const mapStyle = (styleParams) => {

  const canvasBoxBuild = document.getElementById("canvas-box-build");
  const mapStyle = canvasBoxBuild.querySelector('#map-canvas-box-build img')
  mapStyle.style.width = styleParams.mapWidth;
  mapStyle.style.marginLeft = styleParams.marginCenter;
  mapStyle.style.marginRight = styleParams.marginCenter;

  // mapStyle.style.transform = 'rotate(90deg)';

}

export { mapStyle }
