import { buildCanvas } from '../canvas.js'

const buildMapCanvas = () => {
  buildCanvas({
    'element': map,
    'urlCanvas': map.getCanvas().toDataURL('image/png', 1.0)
  });

}

export { buildMapCanvas }
